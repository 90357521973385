import { giphyUrl } from '@/app/util/env'

// enable TrackJS for only 1% of traffic
const shouldReport = giphyUrl === 'https://giphy.com' ? Math.random() <= 0.01 : false

const initTrackJS = async (error?: any) => {
    if (shouldReport) {
        const TrackJS = (await import('trackjs')).TrackJS
        if (!TrackJS.isInstalled()) {
            TrackJS.install({
                token: '00a54533935841dea5b9a1d9b7395dd3',
                application: 'giphy-next',
                network: { error: false },
                window: { promise: false },
            })
        }
        if (error) {
            TrackJS.track(error)
        }
    }
}

export const reportTrackJSError = (error: any) => {
    initTrackJS(error)
}

initTrackJS()
